import axios from 'axios';

export const ApiService = {
    apiBaseUrl: process.env.VUE_APP_MATCHR_URL,

    getRates() {
        return axios.get(`${this.apiBaseUrl}/api/v1/orders`);
    },

    getInternalRates() {
        return axios.get(`${this.apiBaseUrl}/api/v1/orders?type=internal`);
    },

    getMarketMakers() {
        return axios.get(`${this.apiBaseUrl}/api/v1/market-makers`);
    },

    getCurrencies() {
        return axios.get(`${this.apiBaseUrl}/api/v1/currencies`);
    }
};
