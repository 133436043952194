
import { Component, Vue } from 'vue-property-decorator';
import Buefy from 'buefy'

import RatesTable from './components/RatesTable.vue';

@Component({
  components: {
    RatesTable,
  },
})
export default class App extends Vue {}

Vue.use(Buefy)
