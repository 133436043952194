
import { Component, Prop, Vue } from 'vue-property-decorator';

import { Rate } from '@/types/Rate';
import { ApiService }  from '@/services/ApiService';

@Component
export default class RatesTable extends Vue {
    currenciesTo: string[] = [];
    currenciesFrom: string[] = [];
    marketMakers: string[] = [];
    lastUpdate: Date = new Date();
    isLoading: boolean = true;
    filters = {
        marketMaker: null,
        to: null,
        from: null,
    };

    private allRates: Rate[] = [];

    get rates() {
        let rates: Rate[] = this.allRates; 
        if (this.filters.marketMaker) {
            rates = rates.filter(rate => {
                return rate.marketMakerId == this.filters.marketMaker;
            })
        }
        if (this.filters.to) {
            rates = rates.filter(rate => {
                return rate.to == this.filters.to;
            })
        }
        if (this.filters.from) {
            rates = rates.filter(rate => {
                return rate.from == this.filters.from;
            })
        }
        return rates;
    }

    set rates (newRates) {
        this.allRates = newRates;
    }

    created () {
        this.getRates();
        this.getCurrencies();
        this.getMarketMakers();
    }

    getRates() {
        this.isLoading = true;

        Promise.all([
            ApiService.getRates(),
            ApiService.getInternalRates()
        ])
        .then(([mainResponse, internalResponse]) => {
            let newRatesMain: Rate[] = [];
            for (let order of mainResponse.data.orders) {
                newRatesMain.push({
                    marketMakerId: order.market_maker_id,
                    from: order.from,
                    to: order.to,
                    amount: parseFloat(order.amount),
                    rate: parseFloat(order.rate),
                    min_amount: parseFloat(order.min_amount),
                    type: '',
                })
            }

            let newRatesInternal: Rate[] = [];
            for (let order of internalResponse.data.orders) {
                newRatesMain.push({
                    marketMakerId: order.market_maker_id,
                    from: order.from,
                    to: order.to,
                    amount: parseFloat(order.amount),
                    rate: parseFloat(order.rate),
                    min_amount: parseFloat(order.min_amount),
                    type: 'internal',
                })
            }

            this.rates = newRatesMain.concat(newRatesInternal);
            this.lastUpdate = new Date();
            this.isLoading = false;
        })
        .catch((error) => {
            this.showErrorToast();
        });
    }

    getCurrencies () {
        ApiService.getCurrencies()
        .then((response) => {
            this.currenciesTo = response.data.to;
            this.currenciesFrom = response.data.from;
        })
        .catch((error) => {
            this.showErrorToast();
        })
    }

    getMarketMakers () {
        ApiService.getMarketMakers()
        .then((response) => {
            this.marketMakers = response.data['market-makers'];
        })
        .catch((error) => {
            this.showErrorToast();
        })
    }

    private showErrorToast() {
        this.$buefy.toast.open({
            duration: 3000,
            message: `Server error is occured`,
            type: 'is-danger'
        })
    }
}
